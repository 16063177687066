<template>
    <div class="edit-scale">
        <div class="edit-scale__sidebar">
            <Sidebar />
        </div>
        <div class="edit-scale__container">
            <h1 class="edit-scale__container__tittle">Scale Rates</h1>
            <hr class="edit-scale__container__separator" />
            <h3>File name: {{ filesScalesRates.name}}</h3>
            <Button icon="pi pi-arrow-left"
                class="p-button-rounded p-button-outlined edit-scale__container__back-button"
                    @click="backToScale()" />
            <div class="edit-scale__container__content">
                <TableScalesRates :file="filesScalesRates" />
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
import Sidebar from '../../../../components/Sidebar.vue';
import TableScalesRates from './TableScalesRates.vue';

export default {
  name: 'EditScaleRates',
  props: {
    file: {
      type: File,
      default: null,
    },
  },
  components: {
    Sidebar,
    TableScalesRates,
  },
  data() {
    return {
      totalErrors: 0,
      filesScalesRates: null,
    };
  },
  created() {
    this.filesScalesRates = this.file;
  },
  methods: {
    backToScale() {
      this.$router.push({ name: 'ScalesRates' });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-scale {
    height: 90vh;
    display: flex;

    &__sidebar {
        width: 9%;
    }

    &__container {
        width: 91%;
        margin: 2rem;

        &__tittle {
            font-weight: bold;
        }

        &__separator {
            width: 98%;
            margin: 2rem auto;
        }

        &__back-button {
            @include button-outlined;
            margin-bottom: 2rem;
            float: left;
            left: 0.5rem;
        }

        &__content {
            width: 100%;
            margin: 2rem auto;
            height: 65rem;

            &__row {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin: 2.5rem 0 1rem;

                &--button {
                    @include primary-button;
                    margin-right: 0.5rem;
                }
            }

            &--button {
                @include primary-button;
                margin-right: 0.5rem;
            }
        }
    }
}

@media (max-width: 900px) {
    &__content {
        width: 98%;
        margin-top: 7rem;
    }
}

:deep(.custom-file-label::after) {
    color: $color-white;
    background-color: $color-primary-button;
    border-color: $color-primary-button;
}

:deep(thead) {
    background-color: #efeff0;
    color: $canned-tables-color;
}
</style>
