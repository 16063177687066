<template>
    <div class="Table" :key="renderKey">
        <div class="table__header">
            <div>
                <h6><b>Total rows with errors:</b> {{ totalErrors }}</h6>
            </div>
            <div class="table__end-align">
                <b-overlay :show="isLoading" rounded opacity="0.6"
                    spinner-small class="d-inline-block">
                    <b-button :disabled="isLoading" class="table__end-align--button"
                        @click="sendScale()"><b-icon
                            icon="reply-fill" aria-hidden="true"></b-icon>
                                Send Quote</b-button>
                </b-overlay>
            </div>
        </div>
        <b-table id="scales-table" :items="items" :fields="fields"
        :per-page="pageSize" :current-page="currentPage"
            show-empty>
            <template #empty="scope">
                <h6 class="emptyText">{{ scope.emptyText }}</h6>
            </template>
            <template #cell()="row">
                <b-form-select v-if="(row.field.key === 'country_pickup'
                    || row.field.key === 'country_delivery') &&
        !countriesOptions.find((opt) => opt.value === row.value) &&
        !validateCountryInput(row)
        " v-model="row.value" :options="countriesOptions"
            :state="validateCountryInput(row)" required
                    @change="updateCountryField(row)">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            -- Select a country--</b-form-select-option>
                    </template>
                    <template #option="{ option }">
                        {{ option.text }}
                    </template>
                </b-form-select>
                <span v-if="(row.field.key === 'country_pickup'
                    || row.field.key === 'country_delivery') &&
        !validateCountryInput(row)
        " class="invalid-feedback d-block" :style="`font-size: 12px`">
                    <span v-if="!countriesOptions.find((opt) =>
                        opt.value === getUpperCaseValue(row.value)) &&
        (row.field.key === 'country_pickup' || row.field.key === 'country_delivery')
        " :style="`color: red; font-weight: bold`">
                        {{ "*" + getUpperCaseValue(row.value) }}
                    </span>
                    Please select a valid country
                </span>
                <b-form-input v-else-if="row.field.key !== 'index'" type="text"
                    :value="row.value" v-model="row.value"
                    :class="row.field.key + row.index"
                    :style="`border: 1px solid ${isFieldInvalid(row) ? '#F44336' : '#00B900'}`"
                    @change="verifyFields(row)">
                </b-form-input>
                <div v-else>{{ row.item.index + 1 }}</div>
            </template>
        </b-table>
        <el-pagination
      background
      layout="prev, pager, next"
      :total="totalRows"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import * as XLSX from 'xlsx';

export default {
  name: 'TableScalesRates',
  props: {
    file: {
      type: File,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        'index',
        'country_pickup',
        'zip_pickup',
        'country_delivery',
        'zip_delivery',
        'weight',
        'weight_units',
        'density',
      ],
      items: [],
      isThereError: false,
      pageSize: 10,
      currentPage: 1,
      rows: 0,
      isLoading: false,
      totalErrors: 0,
      validations: {
        canadaZipCode: /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
        weight: /^(lb|kg|ton)$/i,
        number: /^-?\d+(\.\d+)?$/,
        country: /^(us|mx|ca)$/i,
      },
      countriesOptions: [
        { value: 'US', text: 'US' },
        { value: 'MX', text: 'MX' },
        { value: 'CA', text: 'CA' },
      ],
      countries: ['US', 'MX', 'CA'],
      renderKey: 0,
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  mounted() {
    if (this.file instanceof FileList) {
      this.$message({
        showClose: true,
        type: 'success',
        message: 'The file has been successfully uploaded',
        showConfirmButton: false,
        duration: 2000,
      });
      this.readingFile({ target: this.file });
    } else if (this.file instanceof File) {
      this.$message({
        showClose: true,
        type: 'success',
        message: 'The file has been successfully uploaded',
        showConfirmButton: false,
        duration: 2000,
      });
      this.readingFile({ target: [this.file] });
    }
  },
  methods: {
    changeAvailablePage(pageNumber) {
      this.currentPage = pageNumber;
      // eslint-disable-next-line
      this.tableKey++;
    },
    handleCurrentChange(val) {
      this.changeAvailablePage(val);
    },
    async readingFile(row) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      // eslint-disable-next-line func-names
      reader.onload = async function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        this.scalesJson = XLSX.utils.sheet_to_json(worksheet);
        // Group rows by zipcode condition
        const groupedRows = {};
        this.scalesJson.forEach((element, index) => {
          const pickupZip = element.zip_pickup.toString() && element.zip_pickup.toString();
          const deliveryZip = element.zip_delivery.toString() && element.zip_delivery.toString();
          const key = `${pickupZip.toString() ? pickupZip.length : ''}_${deliveryZip.toString() ? deliveryZip.length : ''}`;
          if (!groupedRows[key]) {
            groupedRows[key] = [];
          }
          groupedRows[key].push({ element, index });
        });
        await this.scalesJson.forEach(async (element, index) => {
          const inputElements = document.querySelectorAll(`.${element.country_pickup + index}`);
          // eslint-disable-next-line
          element.inputElements = inputElements;
          if (element.zip_pickup && element.zip_delivery) {
            const pickupZip = element.zip_pickup.toString();
            const deliveryZip = element.zip_delivery.toString();
            // eslint-disable-next-line
            if (this.getLength(pickupZip) === 4 && element.country_pickup.toUpperCase() === 'US'
                  // eslint-disable-next-line
                  || this.getLength(deliveryZip) === 4 && element.country_delivery.toUpperCase() === 'US') {
              // Show confirmation dialog for all affected rows
              this.showConfirmationDialog(groupedRows);
            }
          }
        });
        await this.validateFile(row);
      }.bind(this);
      this.renderKey += 1;
    },
    getLength(value) {
      return String(value).length;
    },
    async showConfirmationDialog(groupedRows) {
      let hasShownChangeMessage = false;
      let changeMessages = '';
      Object.keys(groupedRows).forEach((key) => {
        const rows = groupedRows[key];
        if (hasShownChangeMessage && rows.length > 1) {
          rows.forEach((row) => {
            const pickupZip = row.element.zip_pickup ? row.element.zip_pickup.toString() : '';
            const deliveryZip = row.element.zip_delivery ? row.element.zip_delivery.toString() : '';
            if ((row.element.country_pickup === 'US'
                  && this.getLength(pickupZip) === 4)
                  || (row.element.country_delivery === 'US'
                      && this.getLength(deliveryZip) === 4)) {
              // eslint-disable-next-line
              changeMessages += 'In the file you have found US zip codes with 4 digits, would you like to continue to correct each one?';
            }
          });
        } else {
          const pickupZip = rows[0].element.zip_pickup ? rows[0].element.zip_pickup.toString() : '';
          const deliveryZip = rows[0].element.zip_delivery ? rows[0].element.zip_delivery.toString() : '';
          // eslint-disable-next-line
          if ((rows[0].element.country_pickup === 'US' && this.getLength(pickupZip) === 4) || (rows[0].element.country_delivery === 'US' && this.getLength(deliveryZip) === 4)) {
            // eslint-disable-next-line
              changeMessages += 'In the file you have found US zip codes with 4 digits, would you like to continue to correct each one?';
          }
        }
      });
      changeMessages = changeMessages.trim();
      if (changeMessages) {
        const result = await this.$swal({
          title: 'US Zipcode Interpretation',
          text: changeMessages,
          icon: 'info',
          confirmButtonText: 'Confirm',
        });
        if (result.isConfirmed) {
          Object.keys(groupedRows).forEach((key) => {
            const rows = groupedRows[key];
            const fieldsToVerify = [];
            rows.forEach((row) => {
              if ((row.element.country_pickup === 'US' && this.getLength(row.element.zip_pickup) === 4)) {
                fieldsToVerify.push({
                  item: row.element,
                  field: { key: 'zip_pickup' },
                  index: row.index,
                  value: row.element.zip_pickup,
                });
              }
              if ((row.element.country_delivery === 'US' && this.getLength(row.element.zip_delivery) === 4)) {
                fieldsToVerify.push({
                  item: row.element,
                  field: { key: 'zip_delivery' },
                  index: row.index,
                  value: row.element.zip_delivery,
                });
              }
            });
            this.verifyZipCodeFields(fieldsToVerify);
          });
          hasShownChangeMessage = true;
        } else {
          Object.keys(groupedRows).forEach((key) => {
            const rows = groupedRows[key];
            const fieldsToVerify = [];
            rows.forEach((row) => {
              if ((row.element.country_pickup === 'US' && this.getLength(row.element.zip_pickup) === 4)) {
                fieldsToVerify.push({
                  item: row.element,
                  field: { key: 'zip_pickup' },
                  index: row.index,
                  value: row.element.zip_pickup.toString(),
                });
              }
              if ((row.element.country_delivery === 'US' && this.getLength(row.element.zip_delivery) === 4)) {
                fieldsToVerify.push({
                  item: row.element,
                  field: { key: 'zip_delivery' },
                  index: row.index,
                  value: row.element.zip_delivery.toString(),
                });
              }
            });
            this.verifyZipCodeFields(fieldsToVerify);
          });
        }
        return result.isConfirmed;
      }
      return true;
    },
    verifyAllFields() {
      this.scalesJson.forEach((scale, index) => {
        Object.keys(scale).forEach((key) => {
          if (key !== 'index' && key !== 'inputElements') {
            this.verifyZipCodeFields([{
              item: scale,
              field: { key },
              index,
              value: scale[key],
            }]);
          }
        });
      });
    },
    async validateFile() {
      const infoScaleErrors = [];
      this.items = [];
      let totalErrors = 0;
      this.scalesJson.forEach((element, index) => {
        const errorCordinates = {};
        this.isThereError = false;
        this.scalesJson[index].index = index;
        const requiredFields = ['weight', 'weight_units', 'density'];
        requiredFields.forEach((field) => {
          if (!element[field]) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        const numericFields = ['weight', 'weight_units', 'density'];
        numericFields.forEach((field) => {
          if (!this.validations.number.test(element[field])) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        if (element.zip_delivery && element.zip_pickup) {
          const deliverySize = element.zip_delivery.toString();
          const pickupSize = element.zip_pickup.toString();
          if (
            !(this.validations.number.test(element.zip_delivery) && deliverySize.length >= 5)
              && !this.validations.canadaZipCode.test(element.zip_delivery)
          ) {
            errorCordinates.zip_delivery = false;
            this.isThereError = true;
          }
          if (
            !(this.validations.number.test(element.zip_pickup) && pickupSize.length >= 5)
              && !this.validations.canadaZipCode.test(element.zip_pickup)
          ) {
            errorCordinates.zip_pickup = false;
            this.isThereError = true;
          }
        } else if (element?.zip_delivery && !element?.zip_pickup) {
          errorCordinates.zip_pickup = false;
          this.isThereError = true;
        } else if (element?.zip_pickup && !element?.zip_delivery) {
          errorCordinates.zip_delivery = false;
          this.isThereError = true;
        }
        if (element.country_delivery && element.country_pickup) {
          // eslint-disable-next-line
          const countryPickupValid = this.validations.country.test(
          element.country_pickup?.toUpperCase(),
          );
          // eslint-disable-next-line
          const countryDeliveryValid = this.validations.country.test(
            element.country_delivery?.toUpperCase(),
          );
          if (
            !(countryPickupValid)
          ) {
            errorCordinates.country_pickup = false;
            this.isThereError = true;
          }
          if (
            !(countryDeliveryValid)
          ) {
            errorCordinates.country_delivery = false;
            this.isThereError = true;
          }
        } else if (element?.country_delivery && !element?.zip_pickup) {
          errorCordinates.country_pickup = false;
          this.isThereError = true;
        } else if (element?.country_pickup && !element?.country_delivery) {
          errorCordinates.country_delivery = false;
          this.isThereError = true;
        }
        if (Object.keys(errorCordinates).length > 0) {
          this.items.push(element);
          // eslint-disable-next-line
          totalErrors++;
        } else {
          infoScaleErrors.push(errorCordinates);
        }
        this.totalErrors = totalErrors;
      });
      setTimeout(() => {
        this.underLineErrors(infoScaleErrors);
      }, 500);
    },
    updateCountryField(row) {
      const upperCaseValue = this.getUpperCaseValue(row.value);
      this.scalesJson[row.item.index][row.field.key] = upperCaseValue;
      // eslint-disable-next-line
      row.value = upperCaseValue;
    },
    validateCountryInput(row) {
      if (row.field.key === 'country_pickup' || row.field.key === 'country_delivery') {
        const upperCaseValue = this.getUpperCaseValue(row.value);
        return this.isCountryValid(upperCaseValue);
      }
      return null;
    },
    getUpperCaseValue(value) {
      return typeof value === 'string' ? value.toUpperCase() : value;
    },
    isCountryValid(country) {
      return ['US', 'MX', 'CA'].includes(country.toUpperCase());
    },
    async underLineErrors(infoScaleErrors) {
      infoScaleErrors.forEach((rowInfo, index) => {
        const rowArray = Object.keys(rowInfo);
        rowArray.forEach((key) => {
          const inputElements = document.querySelectorAll(`.${key}${index}`);
          if (inputElements.length > 0) {
            inputElements[0].classList.add('error-border');
          }
        });
      });
    },
    isFieldInvalid(row) {
      const fieldKey = row.field ? row.field.key : null;
      const fieldValue = row.value || '';
      if (!fieldValue && fieldKey !== 'index') return true;
      if ((fieldKey === 'zip_pickup' || fieldKey === 'zip_delivery')) {
        if (this.scalesJson[row.item.index]?.country_pickup?.toUpperCase() === 'US') {
          return fieldValue.length === 4;
        }
        if (this.scalesJson[row.item.index]?.country_delivery?.toUpperCase() === 'US') {
          return fieldValue.length === 4;
        }
      }
      if (this.validations.weight.test(fieldValue)) return false;
      if (this.validations.number.test(fieldValue)) return false;
      if (this.isCountryValid(fieldValue)) return false;
      return true;
    },
    async verifyFields(row) {
      const input = document.getElementsByClassName(row.field.key + row.index);
      if (row.field.key.includes('zip')) {
        if (!this.validations.canadaZipCode.test(row.value)) {
          if (this.validations.number.test(row.value) && row.value.length >= 5) {
            input[0].attributes[4].value = 'border: 1px solid #00B900';
            this.scalesJson[row.item.index][row.field.key] = row.value;
          } else if ((row.item.country_pickup === 'US' && this.getLength(row.value) === 4 && row.field.key === 'zip_pickup') || (row.item.country_delivery === 'US' && this.getLength(row.value) === 4 && row.field.key === 'zip_delivery')) {
            input[0].attributes[4].value = 'border: 1px solid #F44336';
            // eslint-disable-next-line
            const fullZipcode = '0' + row.value;
            this.scalesJson[row.item.index][row.field.key] = fullZipcode;
            // eslint-disable-next-line
            row.value = fullZipcode;
            this.$swal({
              title: 'US Zipcode Interpretation',
              // eslint-disable-next-line
              text: 'We interpreted the zipcode "' + row.value + '" as "' + fullZipcode + '". Please confirm.',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (!result.isConfirmed) {
                input[0].attributes[4].value = 'border: 1px solid #F44336';
                this.scalesJson[row.item.index][row.field.key] = row.value.slice(1);
              } else {
                input[0].attributes[4].value = 'border: 1px solid #00B900';
              }
            });
          } else {
            input[0].attributes[4].value = 'border: 1px solid #F44336';
          }
        } else {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        }
      } else if (row.field.key === 'weight_units') {
        if (this.validations.weight.test(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      } else if (row.field.key === 'density' || row.field.key === 'weight') {
        if (this.validations.number.test(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      } else if (row.field.key === 'country_pickup' || row.field.key === 'country_delivery') {
        if (this.isCountryValid(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value.toUpperCase();
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      }
    },
    async verifyZipCodeFields(rows) {
      const fullZipCodes = [];
      rows.forEach(async (row) => {
        const fieldKey = row.field.key;
        const fieldValue = row.value || '';
        const inputElement = document.querySelectorAll(`.${fieldKey}${row.index}`);
        if (!inputElement) return;
        if ((fieldKey === 'zip_pickup' || fieldKey === 'zip_delivery')) {
          if (!this.validations.canadaZipCode.test(fieldValue)) {
            if (this.validations.number.test(fieldValue) && fieldValue.length >= 5) {
              inputElement.style.border = '1px solid #00B900';
              this.scalesJson[row.index][fieldKey] = fieldValue;
            } else if ((fieldKey === 'zip_pickup' && row.item.country_pickup.toUpperCase() === 'US'
                && this.getLength(fieldValue) === 4) || (fieldKey === 'zip_delivery'
                && row.item.country_delivery.toUpperCase() === 'US'
                && this.getLength(fieldValue) === 4)) {
              const fullZipCode = fieldValue;
              fullZipCodes.push({ index: row.index, fieldKey, value: fullZipCode });
              this.scalesJson[row.index][fieldKey] = fullZipCode;
              await this.showFullZipCodeDialog(fullZipCodes);
            }
          } else {
            inputElement.style.border = '1px solid #00B900';
            this.scalesJson[row.index][fieldKey] = fieldValue;
          }
        } else if (fieldKey === 'country_pickup' || fieldKey === 'country_delivery') {
          if (this.isCountryValid(fieldValue)) {
            inputElement.style.border = '1px solid #00B900';
            this.scalesJson[row.index][fieldKey] = fieldValue.toUpperCase();
          } else {
            inputElement.style.border = '1px solid #F44336';
          }
        } else if (this.validations.number.test(fieldValue)) {
          inputElement.style.border = '1px solid #00B900';
          this.scalesJson[row.index][fieldKey] = Number(fieldValue);
        } else {
          inputElement.style.border = '1px solid #F44336';
        }
      });
    },
    async showFullZipCodeDialog(fullZipCodes) {
      const zipcodesString = fullZipCodes.map(({ fieldKey, value }) => `${fieldKey}: ${value}`).join(', ');
      const result = await this.$swal({
        title: 'US Zipcode Interpretation',
        text: `We interpreted the following zipcodes: "${zipcodesString}". Do you want to add a 0 at the beginning?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
      fullZipCodes.forEach(({ index, fieldKey, value }) => {
        const inputElement = document.querySelectorAll(`.${fieldKey}${index}`);
        if (inputElement) {
          if (result.isConfirmed) {
            // eslint-disable-next-line
            this.scalesJson[index][fieldKey] = '0' + value;
            inputElement.style.border = '1px solid #00B900';
          } else {
            this.scalesJson[index][fieldKey] = value;
            inputElement.style.border = '1px solid #F44336';
          }
        }
      });
    },
    lineItemsDimension(weightBrakes, density, unitsWeight) {
      const dimensionsArray = [];
      weightBrakes.forEach((weight, index) => {
        let realWeight = 0;
        let hu = 1;
        let dimensions = null;
        switch (unitsWeight[index]) {
        case 'kg':
          realWeight = weight * 2.205;
          break;
        case 'ton':
          realWeight = weight * 2000;
          break;
        default:
          realWeight = weight;
        }
        do {
          dimensions = realWeight / hu / density[index];
          // eslint-disable-next-line
          dimensions = dimensions * 1728;
          // eslint-disable-next-line
          dimensions = dimensions ** (1 / 3);
          dimensions = dimensions.toFixed(2);
          // eslint-disable-next-line
          hu++;
        } while (dimensions >= 96);
        dimensionsArray.push({ hu, dimensions, weight });
      });
      return dimensionsArray;
    },
    async sendScale() {
      if (this.totalErrors === this.items.length) {
        this.isLoading = true;
        const weightBrakesArray = [];
        const densityArray = [];
        const weightUnitsArray = [];
        this.scalesJson.forEach((element) => {
          weightBrakesArray.push(element.weight);
          densityArray.push(element.density);
          weightUnitsArray.push(element.weight_units);
        });
        const dimensions = await this.lineItemsDimension(
          weightBrakesArray,
          densityArray,
          weightUnitsArray,
        );
        const hauls = this.haulsConstructor(dimensions);
        this.$store.commit('scale/setHauls', hauls);
        const response = await this.$store.dispatch('scale/sendQuote');
        if (response.status === 202) {
          this.$swal({
            icon: 'success',
            title: 'The scale has been sent',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.$router.push({ name: 'ScalesRates', params: { resetInfo: true } });
          });
        }
        this.isLoading = false;
      } else {
        this.$swal({
          title: 'Error',
          text: 'Please resolve all fields problems',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e53935',
        });
      }
    },
    haulsConstructor(dimensions) {
      const hauls = [];
      // eslint-disable-next-line
      const scalesJson = this.scalesJson;
      dimensions.forEach((dimension, index) => {
        const lineItems = {
          weight: dimension.weight,
          length: parseFloat(dimension.dimensions),
          width: parseFloat(dimension.dimensions),
          height: parseFloat(dimension.dimensions),
          hu_count: dimension.hu,
        };
        const haul = {
          order: hauls.length + 1,
          line_items: [lineItems],
          zip_from: [],
          zip_to: [],
          country_from: [],
          country_to: [],
        };
        if (index < scalesJson.length) {
          const scaleElement = scalesJson[index];
          if ((!scaleElement.zip_pickup && !scaleElement.zip_delivery)
            || (!scaleElement.country_pickup && !scaleElement.country_delivery)) {
            // Do nothing if there is no pickup or delivery information
          } else {
            haul.zip_from = String(scaleElement.zip_pickup);
            haul.zip_to = String(scaleElement.zip_delivery);
            haul.country_from = scaleElement.country_pickup.toString().toUpperCase();
            haul.country_to = scaleElement.country_delivery.toString().toUpperCase();
          }
        }
        hauls.push(haul);
      });
      return hauls;
    },
  },
};
</script>

<style scoped lang="scss">
.table {
    &__pagination {
        position: relative;
        z-index: 0;
        padding-bottom: 6rem;
        margin-bottom: 50px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__end-align {
        display: flex;
        justify-content: end;
        margin-top: 2rem;

        &--button {
            @include primary-button;
            margin: 30px;
        }
    }
}
.emptyText {
  display: flex;
  height: 35rem;
  flex-direction: column;
  justify-content: center;
}
.error-border {
    border: 1px solid #f44336;
}
</style>
