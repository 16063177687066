var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.renderKey,staticClass:"Table"},[_c('div',{staticClass:"table__header"},[_c('div',[_c('h6',[_c('b',[_vm._v("Total rows with errors:")]),_vm._v(" "+_vm._s(_vm.totalErrors))])]),_c('div',{staticClass:"table__end-align"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isLoading,"rounded":"","opacity":"0.6","spinner-small":""}},[_c('b-button',{staticClass:"table__end-align--button",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.sendScale()}}},[_c('b-icon',{attrs:{"icon":"reply-fill","aria-hidden":"true"}}),_vm._v(" Send Quote")],1)],1)],1)]),_c('b-table',{attrs:{"id":"scales-table","items":_vm.items,"fields":_vm.fields,"per-page":_vm.pageSize,"current-page":_vm.currentPage,"show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('h6',{staticClass:"emptyText"},[_vm._v(_vm._s(scope.emptyText))])]}},{key:"cell()",fn:function(row){return [((row.field.key === 'country_pickup'
                || row.field.key === 'country_delivery') &&
    !_vm.countriesOptions.find((opt) => opt.value === row.value) &&
    !_vm.validateCountryInput(row)
    )?_c('b-form-select',{attrs:{"options":_vm.countriesOptions,"state":_vm.validateCountryInput(row),"required":""},on:{"change":function($event){return _vm.updateCountryField(row)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" -- Select a country--")])]},proxy:true},{key:"option",fn:function({ option }){return [_vm._v(" "+_vm._s(option.text)+" ")]}}],null,true),model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}):_vm._e(),((row.field.key === 'country_pickup'
                || row.field.key === 'country_delivery') &&
    !_vm.validateCountryInput(row)
    )?_c('span',{staticClass:"invalid-feedback d-block",style:(`font-size: 12px`)},[(!_vm.countriesOptions.find((opt) =>
                    opt.value === _vm.getUpperCaseValue(row.value)) &&
    (row.field.key === 'country_pickup' || row.field.key === 'country_delivery')
    )?_c('span',{style:(`color: red; font-weight: bold`)},[_vm._v(" "+_vm._s("*" + _vm.getUpperCaseValue(row.value))+" ")]):_vm._e(),_vm._v(" Please select a valid country ")]):(row.field.key !== 'index')?_c('b-form-input',{class:row.field.key + row.index,style:(`border: 1px solid ${_vm.isFieldInvalid(row) ? '#F44336' : '#00B900'}`),attrs:{"type":"text","value":row.value},on:{"change":function($event){return _vm.verifyFields(row)}},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}):_c('div',[_vm._v(_vm._s(row.item.index + 1))])]}}])}),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.totalRows,"page-size":_vm.pageSize,"current-page":_vm.currentPage},on:{"current-change":_vm.handleCurrentChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }